.q-pipi {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  text-align: center;

  h1 {
    display: flex;
    font-size: 20vw;
    justify-content: center;
    margin-bottom: 2vw;

    img {
      width: 1em;
      object-fit: contain;
    }
  }
}

.q-pipi-wave {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: black;
}

.ocean {
  height: 5%;
  width:100%;
  position:absolute;
  bottom:0;
  left:0;
  background: #C9B684;
}

.wave {
  background: url('/assets/img/wave.svg') repeat-x;
  position: absolute;
  top: -198px;
  width: 6400px;
  height: 198px;
  animation: wave 7s cubic-bezier( 0.36, 0.45, 0.63, 0.53) infinite;
  transform: translate3d(0, 0, 0);
}

.wave:nth-of-type(2) {
  top: -175px;
  animation: wave 7s cubic-bezier( 0.36, 0.45, 0.63, 0.53) -.125s infinite, swell 7s ease -1.25s infinite;
  opacity: 1;
}

@keyframes wave {
  0% {
    margin-left: 0;
  }
  100% {
    margin-left: -1600px;
  }
}

@keyframes swell {
  0%, 100% {
    transform: translate3d(0,-25px,0);
  }
  50% {
    transform: translate3d(0,5px,0);
  }
}
