body {
  background: white;
  color: white;
  height: 600vh;
  position: relative;
  font-family: Helvetica;

  &.no-scroll {
    overflow: hidden;
  }
}

#c {
  width: 100%;
  height: 100%;
  position: fixed;
  display: block;
  z-index: -1;
  top: 0;
  left: 0;
}


@keyframes logoFadeIn {
  0% {
    transform: translate3d(0, -1000px, 0);
  }

  80% {
    opacity: 0;
  }

  100% {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

.end {
  font-size: 34px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translate3d(0, 100%, 0);

  .rip {
    font-size: 100px;
    text-align: center;
    font-weight: 700;

    svg {
      width: 70%;
      margin: auto;
      height: auto;
    }

    path {
      fill: white;
    }
  }

  audio {
    visibility: hidden;
    position: absolute;
    top: -9999px;
    left: -9999px;
  }
}

.end__content {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0 24px 32px;
}

.credits {
  font-size: 12px;
  line-height: 1.6;
  text-align: center;

  p:not(:last-child) {
    margin-bottom: 24px;
  }

  a {
    display: inline-block;
    font-weight: 700;

    &:hover {
      text-decoration: underline;
    }
  }
}

.overlay {
  opacity: 0;
  background: black;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.easter-indication {
  position: relative;

  input {
    position: absolute;
    width: 100%;
    //visibility: hidden;
    background: transparent;
  }
}
