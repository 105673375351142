.loader-wp {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  //background: rgba(black, 1);
  z-index: 200;
  opacity: 1;
  transition: opacity 3s ease-out;
  display: flex;

  .sliders {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;

    .slider {
      content: '';
      position: absolute;
      width: 300%;
      left: 0;
      height: 100%;

    }

    .slider--top {
      top: 0;
      background: black;
      transform: translate(-50%, -50%);
      transform-origin: bottom center;
    }

    .slider--bottom {
      bottom: 0;
      background: black;
      transform: translate(-50%, 50%);
      transform-origin: top center;
    }
  }

  .logo {
    filter: invert(1);
    display: block;
    margin: auto;
    align-items: center;
    justify-content: center;
    //width: auto;
    //transform: translate3d(0, -1000px, 0);
    //opacity: 0;

    //animation: logoFadeIn 1000ms  forwards;
    transition: transform ease-in 1000ms 0ms, opacity ease-in 1000ms 0ms;

    width: calc(400 * 100vw / 1792);
  }

  &.loaded {
    //opacity: 0;
    pointer-events: none;

    .logo {
      transform: translate3d(0, 10%, 0);
      opacity: 0;
    }
  }
}
